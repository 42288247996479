@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: local('Karla'), url('../public/fonts/Karla-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: local('Karla'), url('../public/fonts/Karla-Italic.woff') format('woff');
}
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  src: local('Karla'), url('../public/fonts/Karla-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  src: local('Karla'), url('../public/fonts/Karla-BoldItalic.woff') format('woff');
}


body {
  margin: 0;
  font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("/public/assets/img/bg.jpeg");
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form {
  display: flex;
  flex-direction: column;
  width: 50%;
  row-gap: 20px;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
}

.button {
  width: 80%;
  height: 30px;
  border-radius: 5px;
  border-width: 0px;
  background-color: #ff7100;
  cursor: pointer;
  font-weight: bold;
}

.button:hover {
  background-color: white;}

.container {
  display: flex;
  flex-direction: row;
  width: 70vw;
  justify-content: center;
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.input {
  width: 80%;
  height: 30px;
  border-radius: 5px;
  border-width: 1px;
}

.invalid {
  color:#ff7100;
  font-size: 12px;
  margin-top: -18px;
  margin-bottom: -10px;
  margin-left: 8%;
  margin-right: auto;
}

input {
  padding: 2px 10px;
  box-sizing: border-box;
}

.bg-login {
  height: 100vh;
}

.forgot-password {
  color: #ff7100;
  font-size: 14px;
  margin-top: -5px;
  margin-right: 10%;
  margin-left: auto;
}

.register-cta {
  color: #ff7100;
}

.logo {
  filter: invert();
}

input,
button {
  font-family: "Karla";
}

.modal {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 30vw;
  height: auto;
  background-color: white;
}

.modal-container {
  background-color: rgba(0, 0, 0, 0.218);
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  overflow: auto;
}

.modal-header {
  padding: 5px 20px;
  display: flex;
}

.modal-body {
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
}

.close {
  padding: 3px;
  height: 100% !important;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 20px;
  margin-left: auto;
}

.close:hover {
  color: #ff7100;
}
